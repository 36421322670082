import React, {useContext} from 'react';
import styled from 'styled-components';
import {Modal} from 'rsuite';
import Theme from "./helpers/Theme";
import { ModalContext } from '../components/helpers/AppContext';
import { EmailIcon, PhoneIcon, WhatsappIcon} from '../images/contact';

export default function SalesModal() {
  const {openModal, setOpenModal} = useContext(ModalContext);
  const handleClose = () => setOpenModal(false);

  return (
    <Modal
      show={openModal}
      style={{
        width: '100%!important',
        maxWidth: '400px!important',
        marginTop: '0px!important',
        fontFamily: "'Poppins', serif, sans-serif",
        lineHeight: '1.5em',
        borderRadius: '32px!important',
      }}
    >

      <ModalHeader>
        <h1>Baustack®</h1>
        <CloseButton onClick={handleClose}>Cerrar</CloseButton>
      </ModalHeader>

      <ModalBody>
        <h2>¡Pruébalo gratis!</h2>
        <p>Solicita tu prueba gratuita, completamente acompañado por uno de nuestros ejecutivos para garantizar la mejor
          experiencia con Baustack®</p>
        <ButtonBox href="mailto:info@baustack.com">
          <EmailIcon />
          <p><strong>Email:</strong> <span>info@baustack.com</span></p>
        </ButtonBox>
        <ButtonBox target="_blank" href="https://wa.me/56988040293">
          <WhatsappIcon />
          <p><strong>WhatsApp:</strong> <span>+(56) 9 8804 0293</span></p>
        </ButtonBox>
        <ButtonBox href="tel:+56988040293">
          <PhoneIcon />
          <p><strong>Teléfono:</strong> <span>+(56) 9 8804 0293</span></p>
        </ButtonBox>
      </ModalBody>
    </Modal>
  );
};

const ButtonBox = styled.a`
  display: grid;
  grid-template-columns: auto 1fr;
  cursor: pointer;
  gap: 16px;
  border-radius: 8px;
  background-color: ${Theme.darkmauve};
  padding: 16px;
  color: white;
  align-items: center;
  text-decoration: none;
  margin-top: 16px;
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    color: white;
  }
  strong {
    font-weight: 600;
  }
  span {
    white-space: nowrap;
  }
`;

const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;

const CloseButton = styled.button`
  background-color: ${Theme.red};
  padding: 2px 12px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 600;
  color: white;
`;

const ModalBody = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid ${Theme.darkmauve};
  p {
    font-size: 14px;
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 16px;
  }
`;


