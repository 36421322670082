import React from "react";
import { Helmet } from "react-helmet";
import 'rsuite/dist/styles/rsuite-default.css';
import GlobalStyles from "./GlobalStyles";

import SalesModal from "../SalesModal";
import { ModalContext } from "./AppContext";
import styled from 'styled-components';

export default function Layout(props) {
  const [ openModal, setOpenModal ] = React.useState(false);

  const title = props.title || 'Baustack';
  const metaDescription = props.metaDescription || 'Baustack';

  return (
    <ModalContext.Provider value={{ openModal, setOpenModal }}>
      <Helmet>
        <title>{title}</title>

        <meta name="description" content={metaDescription} />
        <meta property="og:image" content="https://baustack.com/images/baustack-brand.png" />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:locale" content="es_LA" />
        <meta property="og:site_name" content="Baustack" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap" rel="stylesheet" />
      </Helmet>
      <GlobalStyles />
      <Main>
        {props.children}
        <SalesModal/>
      </Main>
    </ModalContext.Provider>
  );
}

const Main = styled.main`
  overflow-x: hidden;
  @media screen and (min-width: 768px) {
    overflow-x: initial;
  }
`;